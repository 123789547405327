import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

const Redirect = ({ pageContext, location }) => {
  useEffect(() => {
    const redirectTo = pageContext.toPath;
    let finalRedirectURL = redirectTo;

    const currentQsParams = new URLSearchParams(location?.search || '');
    const hasQsInCurrentUrl = Array.from(currentQsParams.keys()).length > 0;
    if (hasQsInCurrentUrl) finalRedirectURL = `${redirectTo}?${currentQsParams.toString()}`;

    const hasQsInRedirectTo = redirectTo.indexOf('?') > -1;
    if (hasQsInRedirectTo) {
      const redirectToQsParams = new URLSearchParams(redirectTo.substring(redirectTo.indexOf('?') + 1));
      redirectToQsParams.forEach((value, key) => {
        if (!currentQsParams.has(key)) currentQsParams.append(key, value);
      });
      finalRedirectURL = `${redirectTo.substring(0, redirectTo.indexOf('?'))}?${currentQsParams.toString()}`;
    }
    navigate(finalRedirectURL, { replace: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;
};

Redirect.defaultProps = {
  location: {},
};

Redirect.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.string),
};

export default Redirect;
